import React from "react";
import { Link } from "gatsby";
import "./WorkFeature.scss";
import { Waypoint } from "react-waypoint";

const WorkFeature = ({ item }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <Link
        to={`/case-study/${item.uid}/`}
        className={`work-feature waypoint ${active ? "waypoint-active" : ""}`}
        style={{ backgroundImage: `url('${item.data.hero.fluid.src}')` }}
      >
        <div className="work-feature__content align-center">
          <div className="row">
            <div className="col col-md-6 col-md-offset-3">
              <h3 class="heading heading--xs uppercase text--medium">
                {item.data.client_name.text}
              </h3>
              <h3 class="heading heading--l text--black">
                {item.data.title.text}
              </h3>
              {item.categories && item.categories.document && (
                <span>
                  <span className="tag">
                    {item.categories.document.data.title}
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </Link>
    </Waypoint>
  );
};
export default WorkFeature;
