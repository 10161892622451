import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import Prismic from "prismic-javascript";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ListNavigation from "../components/ListNavigation";
import WorkItem from "../components/WorkItem";
import WorkFeature from "../components/WorkFeature";
import { navigate } from "gatsby";
import client from "./../lib/Api";

import "./../components/Work.scss";

export const query = graphql`
  query($cat: String!) {
    allPrismicCaseStudyCategory {
      nodes {
        uid
        data {
          title
        }
      }
    }
    allPrismicCaseStudy(
      sort: { fields: data___featured, order: DESC }
      filter: { data: { category: { uid: { glob: $cat } } } }
    ) {
      nodes {
        data {
          category {
            uid
            document {
              ... on PrismicCaseStudyCategory {
                data {
                  title
                }
              }
            }
          }
          client_name {
            text
          }
          hero {
            alt
            fluid(maxWidth: 1800) {
              src
            }
          }
          title {
            text
          }
          project_name
          thumb {
            alt
            fluid(maxWidth: 1800) {
              src
            }
          }
        }
        uid
      }
    }
    allPrismicWorkPage {
      edges {
        node {
          data {
            title {
              text
            }
            meta_description
            meta_title
            canonical {
              uid
              type
            }
          }
        }
      }
    }
  }
`;

const WorkPage = (props) => {
  const { data, location, pageContext } = props;
  const items = data.allPrismicCaseStudy.nodes;
  const pageData = data.allPrismicWorkPage.edges[0].node.data;
  const categories = data.allPrismicCaseStudyCategory.nodes;
  const meta = {
    descripition: pageData.meta_description,
    title: pageData.meta_title,
    uid: pageData.canonical.uid,
    pageType: pageData.canonical.type,
  };

  const getResults = (items, term = "", keys = []) => {
    const filtered =
      term !== ""
        ? items.filter((item) => {
            return keys.includes(item.uid);
          })
        : items;

    return [
      filtered.filter((item, index) => index < 5),
      filtered.filter((item, index) => index === 5),
      filtered.filter((item, index) => index > 5),
    ];
  };

  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(getResults(items));

  const handleSearch = (term) => {
    // console.log('Search for this==> ', term)

    setLoading(true);

    client
      .query([
        Prismic.Predicates.at("document.type", "case_study"),
        Prismic.Predicates.fulltext("document", term),
      ])
      .then((res) => {
        setLoading(false);
        const data = res.results;
        const keys =
          data.length === 0
            ? []
            : data.map((item) => {
                return item.uid;
              });
        setResults(getResults(items, term, keys));
      });
  };

  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <Layout location={location}>
      <SEO meta={meta} description={meta.descripition} title={meta.title} uid={meta.uid} pageType={meta.pageType} />
      <div className="work page">
        <img
          className={"work-icon"}
          src={require("./../assets/svg/icons/work-color-alt.svg")}
        />
        <div className={`work-header ${active ? "active" : ""}`}>
          <ListNavigation
            title="Case Studies"
            currentCategory={pageContext.cat === "*" ? "" : pageContext.cat}
            categories={categories}
            onSearch={handleSearch}
            onChange={(e) => {
              navigate(`/case-studies/${e.value}`);
            }}
          />
        </div>
        {loading && (
          <div>
            <div className="container text-center margin-verticle">
              <h2>Loading...</h2>
            </div>
          </div>
        )}
        {loading === false && (
          <div>
            {results[0].length === 0 && (
              <div>
                <div className="container text-center margin-verticle">
                  <h2>
                    Sorry, there are no case studies available with this search
                    or filter.
                  </h2>
                </div>
              </div>
            )}
            <div className="container">
              <div className="work-items">
                {results[0].map((item, index) => {
                  return (
                    <WorkItem
                      item={item}
                      featured={index % 10 === 0}
                      size={index === 0 ? "large" : "small"}
                    />
                  );
                })}
              </div>
            </div>
            <div className="work-items">
              {results[1].map((item, index) => {
                return <WorkFeature item={item} />;
              })}
            </div>
            <div className="container">
              <div className="work-items">
                {results[2].map((item, index) => {
                  return <WorkItem item={item} size={"small"} />;
                })}
              </div>
            </div>
          </div>
        )}
        {/* <div className="button-group">
          <button className="button button-round button-gray-outlined button-large">Load More</button>
        </div> */}
      </div>
    </Layout>
  );
};

WorkPage.query = query;

export default withPreview(WorkPage);
