import React, { useState } from "react";
import { Link } from "gatsby";
import { Waypoint } from "react-waypoint";
import "./WorkItem.scss";

const WorkItem = ({ item, size }) => {
  const [active, setActive] = useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`work-item ${size} waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <div className="work-item__inner">
          <div
            className="work-item__background"
            style={{ backgroundImage: `url('${item.data.thumb.fluid.src}')` }}
          ></div>
          <Link to={`/case-study/${item.uid}/`} className="work-item__content">
            <div className="work-item__content-inner">
              <h6 className="client uppercase text--l text--medium">
                {item.data.client_name.text}
              </h6>
              <h3 className="title text--black">{item.data.project_name}</h3>
              {item.data.categories && (
                <span className="tag">
                  {item.data.categories[0].category.document.data.title}
                </span>
              )}
            </div>
          </Link>
        </div>
      </div>
    </Waypoint>
  );
};
export default WorkItem;
